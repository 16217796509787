import "./App.css";
import * as React from "react";
import { useState } from "react";
import {CssBaseline, useMediaQuery, Container, responsiveFontSizes} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";

const App = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const backgroundColor = prefersDarkMode ? "#303030" : "#fafafa";
    let theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? "dark" : "light",
                    primary: {
                        main: '#8FBF8A',
                        darker: '#31402C',
                    },
                }
            }),
        [prefersDarkMode],
    );
    theme = responsiveFontSizes(theme);
    const [user, setUser] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const produceSnackBar = (message, variant="error") => enqueueSnackbar(message, { variant: variant });

    // const webSocket = new WebSocket('ws://localhost:8000/connect4');
    const webSocket = new WebSocket('wss://ws.connect4.dev');
    webSocket.onopen = function() { socketOpen() };
    webSocket.onmessage = function(evt) { socketReceive(evt, ws, setUser) };
    webSocket.onclose = function(evt) { socketClose(evt) };
    webSocket.onerror = function(evt) { socketClose(evt) };
    const ws = {send: (msg) => {console.log("<-", msg); webSocket.send(JSON.stringify(msg))}};

    return (
        <ThemeProvider theme={theme}>
            <meta name="theme-color" content={backgroundColor}/>
            <CssBaseline />
            <SnackbarProvider maxSnack={3} preventDuplicate>
                <GoogleOAuthProvider clientId="152620589473-er10mg2iul6rklq043uoi98bmnu0m7aq.apps.googleusercontent.com">
                    <Container maxWidth={false} disableGutters>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<HomePage produceSnackBar={produceSnackBar} ws={ws} />}/>
                                <Route path="/play" element={ user?.email ?
                                    <MainPage produceSnackBar={produceSnackBar} user={user} setUser={setUser} ws={ws}/> :
                                    <LoginPage produceSnackBar={produceSnackBar} user={user} setUser={setUser} ws={ws}/>
                                }/>
                            </Routes>
                        </BrowserRouter>
                    </Container>
                </GoogleOAuthProvider>
            </SnackbarProvider>
        </ThemeProvider>
    )
}
export default App;

function socketOpen() {
}

function socketClose() {
}
function socketReceive(evt, ws, setUser) {
    let text_message = evt.data;
    let json_message = JSON.parse(text_message);
    let msg_type = json_message["type"]
    console.log("->", json_message);

    if (msg_type === "need_username") {
        // needUsernameCallback(json_message);
    }
    else if (msg_type === "user") {
        // userUpdateCallback(json_message);
        delete json_message['type'];
        delete json_message['message'];
        // email, uuid, picture remain
        setUser(json_message);
    }
    else if (msg_type === "game") {
        // gameUpdateCallback(json_message);
    }
    else if (msg_type === "game_invite") {
        // gameInviteCallback(json_message);
    }
}