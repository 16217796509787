import {Box, Typography} from "@mui/material";
import TopBar from "../components/TopBar";
import * as React from "react";

const MainPage = (props) => {
    return (
        <>
            <TopBar user={props.user}/>
            <Box sx={{ width: "100%", maxWidth: 500, m: 3}}>
                <Typography variant="h2" gutterBottom>
                    Main Page
                </Typography>
            </Box>
        </>
    )
}

export default MainPage;