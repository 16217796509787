import {GoogleLogin} from "@react-oauth/google";
import * as React from "react";
import {Box, Grid, Typography} from "@mui/material";

const LoginPage = (props) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "90vh" }}
        >
            <Grid item xs={3}
            sx={{
                width: "90vw",
                minHeight: "60vh",
                borderRadius: 5,
                p: 1,
                backgroundColor: (t) =>
                    t.palette.mode === "light" ? "#8FBF8A" : "#31402C"
            }}>
                <Typography variant="h3" sx={{textAlign:"center", p: 7}}>
                    Sign in to play Connect4
                </Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <GoogleLogin
                         onSuccess={credentialResponse => {
                             let msg = {"type": "credentials", "credentials": credentialResponse.credential, "user_agent": "web"};
                             props.ws.send(msg);
                         }}
                         onError={() => {
                             props.produceSnackBar("Login Failed!", "error");
                         }}
                         useOneTap
                         auto_select
                    />
                </Box>
                <Box sx={{ fontStyle: 'oblique', mt: 7, ml: 3 }}>
                    More sign-in methods coming soon!
                </Box>
            </Grid>

        </Grid>
    )
}

export default LoginPage;