import {Box, Grid, Paper, Typography, Link} from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Splash from "../images/splash.jpg"

const HomePage = (props) => {
    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid item xs={0} sm={4} md={7} sx={{
                    backgroundImage: `url(${Splash})`,
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={8} s={8} sx={{backgroundColor: "green", width: "100%", p: 4}}>
                        <Typography variant="h2" gutterBottom>
                            Welcome to Connect4!
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            Where you can connect ALL the fours.
                        </Typography>
                        <Box sx={{mt: 4}}>
                            <Link component={RouterLink} to="/play"
                                  sx={{
                                      textDecoration: "none",
                                      padding: "0.5rem 1rem",
                                      backgroundColor: (t) => t.palette.mode === "light" ? "#8FBF8A" : "#31402C",
                                      color: "inherit"
                                  }}>Let's Play</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={4} s={4} sx={{backgroundColor: "blue"}}>
                        <Box sx={{ fontStyle: 'oblique', m: 2 }}>
                            its gonna be fun i promise
                        </Box>
                    </Grid>
                    <Grid item xs={4} s={0} sx={{backgroundColor: "red"}}>
                        YUH
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HomePage;